import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { LOGO_D3VOP_TRANSPARENT } from "../../assets/images/logos";

const Header = () => {
  const [isNavOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen(!isNavOpen);
  };

  useEffect(() => {
    const closeMobileNav = (event) => {
      if (isNavOpen && !event.target.closest(".nav-links-mobile, .nav-toggle")) {
        setNavOpen(false);
      }
    };

    window.addEventListener("click", closeMobileNav);

    return () => {
      window.removeEventListener("click", closeMobileNav);
    };
  }, [isNavOpen]);

  const navLinks = [
    { to: "/", text: "Home" },
    { to: "/about", text: "About Us" },
    { to: "/projects", text: "Projects" },
    { to: "/media", text: "Media" },
    { to: "/blog", text: "Blog" },
    { to: "/contact", text: "Contact" },
  ];

  const renderNavLinks = (isMobile) =>
    navLinks.map((link, index) => (
      <li className="link-wrapper" key={index}>
        <Link to={link.to} lang="en" title={link.text}>
          {link.text}
        </Link>
      </li>
    ));

  return (
    <nav role="navigation">
      <header className="header" id="header">
        <Link className="logo" to="/" title="Logo">
          <img src={LOGO_D3VOP_TRANSPARENT} alt="Logo" title="Logo Image" loading="lazy" />
          <span className="logo-text">vop</span>
        </Link>
        <div
          className={`nav-toggle ${isNavOpen ? "open" : ""}`}
          onClick={toggleNav}
          title="Open Navigation Menu"
        >
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <ul className="nav-links">{renderNavLinks(false)}</ul>
        <ul className={`nav-links-mobile ${isNavOpen ? "open" : ""}`} lang="en">
          {renderNavLinks(true)}
        </ul>
      </header>
    </nav>
  );
};

export default Header;

import { lazy } from "react";

const Home = lazy(() => import("../../pages/home/Home"));
const About = lazy(() => import("../../pages/about/About"));
const Projects = lazy(() => import("../../pages/projects/Projects"));
const Media = lazy(() => import("../../pages/media/Media"));
const Blog = lazy(() => import("../../pages/blog/Blog"));
const Contact = lazy(() => import("../../pages/contact/Contact"));

export { Home, About, Projects, Media, Blog, Contact };
